'use client';
import React, { useEffect, useState } from 'react';
import { ConsentManager } from '@segment/consent-manager';
import isEU from '@segment/in-regions';

import messages from './messages';
import { CookiesBanner } from './CookiesBanner';
import { CookiesBannerButtons } from './CookiesBannerButtons';
import { CookiesBannerAnimation } from './CookiesBannerAnimation';
import { CookiesBannerImage } from './CookiesBannerImage';
import { useGetEnvironmentVariable } from '@/hooks/useGetEnvironmentVariable';

/**
 *
 * IMPORTANT !!!!!
 *
 * To show the banner, you need to configure at least one destination on your segment added to your source
 * so if you can't see the banner check the configured destination on Segment's dashboard
 *
 */

// Array of the regions we want to ask consent
const REGIONS_TO_ASK_CONSENT = ['EU'];

export const CookiesConsentManager = () => {
    // const isClient = useIsClient();

    const [showBanner, setShowBanner] = useState(false);
    const {
        SEGMENT_ANALYTICS_WRITE_KEY,
        SEGMENT_CDN_PROXY_HOST,
        SEGMENT_ANALYTICS_TRACK_WRITE_KEY,
        SEGMENT_API_PROXY_HOST,
    } = useGetEnvironmentVariable([
        'SEGMENT_ANALYTICS_WRITE_KEY',
        'SEGMENT_CDN_PROXY_HOST',
        'SEGMENT_ANALYTICS_TRACK_WRITE_KEY',
        'SEGMENT_API_PROXY_HOST',
    ]);

    const {
        preference_dialog_title,
        preference_dialog_content,
        cancel_dialog_title,
        cancel_dialog_content,
    } = messages;

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // We set a timeout here to wait for Segment to add the analytics object to the window
            setTimeout(() => {
                if (typeof window !== 'undefined' && !!window.analytics) {
                    setShowBanner(true);
                }
            }, 1500);
        }
    }, []);

    // Return if no key is provided
    if (!SEGMENT_ANALYTICS_WRITE_KEY || !SEGMENT_CDN_PROXY_HOST) return null;

    // TODO re implement consent manage but keep in mind this issue
    // https://github.com/segmentio/consent-manager/issues/303

    return (
        <CookiesBannerAnimation>
            {showBanner ? (
                <ConsentManager
                    writeKey={SEGMENT_ANALYTICS_WRITE_KEY}
                    cdnHost={SEGMENT_CDN_PROXY_HOST}
                    shouldRequireConsent={isEU(REGIONS_TO_ASK_CONSENT)}
                    bannerContent={
                        <CookiesBanner bannersImage={<CookiesBannerImage />} />
                    }
                    bannerSubContent="" // Must be and empty string else it will be populated with the default message
                    preferencesDialogTitle={preference_dialog_title}
                    preferencesDialogContent={preference_dialog_content}
                    cancelDialogTitle={cancel_dialog_title}
                    cancelDialogContent={cancel_dialog_content}
                    closeBehavior="dismiss"
                    defaultDestinationBehavior="ask"
                    bannerActionsBlock={(props) =>
                        CookiesBannerButtons({
                            ...props,
                            SEGMENT_CDN_PROXY_HOST,
                            SEGMENT_ANALYTICS_TRACK_WRITE_KEY,
                            SEGMENT_API_PROXY_HOST,
                        })
                    }
                    bannerHideCloseButton={true}
                />
            ) : (
                <></>
            )}
        </CookiesBannerAnimation>
    );
};
