'use client';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { isBefore, subDays } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { SavedCartAttributes } from '../types/cart';
import { getItemFromLocalStorage } from '../state/localStorage';
import {
    emptyCart,
    fetchCartAction,
    fetchCartByExternalIdAction,
} from '../state/actions/cartActions';
import { AppDispatch } from '../state/createStore';

export const restoreCart = (
    storeDispatch: AppDispatch,
    countryCode: string,
    cart: SavedCartAttributes,
) => {
    if (!cart?.id && !cart?.updatedAt) {
        return;
    }
    const tenDaysAgo = subDays(new Date(), 10);

    // If last time the shopping cart was updated is more than 10 days ago
    // or countryCode is not the same with countryCode the last time updated
    // then remove the cart from the local storage
    if (
        isBefore(new Date(cart.updatedAt), tenDaysAgo) ||
        countryCode !== cart.countryCode
    ) {
        storeDispatch(emptyCart());
    } else {
        storeDispatch(
            fetchCartAction({
                cartId: cart.id,
                countryCode,
            }),
        );
    }
};

// Initialize whichever cart is preferred on AppLoad
export const InitializeCartOnAppLoad: FC<{
    children: ReactNode;
    shopifyEndpoint: string;
    adminApiAccessToken: string;
    storefrontAccessToken: string;
}> = ({ children }) => {
    const [actionPerformed, setActionPerformed] = useState(false);
    // Initialize some values
    const dispatch = useAppDispatch();
    const countryCode = useAppSelector(
        (state) => state.geoLocation?.countryCode,
    );
    const geoLocationStatusFetchFinished = useAppSelector(
        (state) =>
            state.geoLocation?.status === 'FETCHED' ||
            state.geoLocation.status === 'FAILED',
    );

    useEffect(() => {
        // There has already been a cart loading action performed, we can cancel
        if (actionPerformed) {
            return;
        }

        // The geolocation has not been retrieved yet, do this first because we need the country
        // our user is in to determine whether we can keep their cart or not
        if (!geoLocationStatusFetchFinished) {
            return;
        }

        // Let's see if there is a clearCart, if so clear cart
        const clearCart = window.location.search.includes('clearCart');

        if (clearCart) {
            setActionPerformed(true);
            dispatch(emptyCart());
        }

        // First let's see if we need to load a cart from the URL
        const splitPathName = window.location.search.split('/');
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, page, pageId] = splitPathName;

        // When the URL is /cart/ and you split it the result is ['', 'cart', ''], so still three long even though last value is undefined
        if (splitPathName.length >= 3 && page === 'cart' && pageId !== '') {
            setActionPerformed(true);
            dispatch(
                fetchCartByExternalIdAction({
                    cartId: pageId,
                    countryCode,
                }),
            );
        }

        // Nothing in URL, try to load it from local
        const cart: SavedCartAttributes = getItemFromLocalStorage('cart');
        if (!actionPerformed && cart?.id && cart?.updatedAt) {
            setActionPerformed(true);

            restoreCart(dispatch, countryCode, cart);
        }
    }, [
        actionPerformed,
        countryCode,
        geoLocationStatusFetchFinished,
        dispatch,
    ]);

    return <>{children}</>;
};
