import { createSlice } from "@reduxjs/toolkit";
import {
	EventMerchandise,
	normalizeContentfulDeliveryResponse,
} from "onair_frontend-lib";
import {
	CART_ATTRIBUTE,
	CART_INDEX_OF_ATTRIBUTE,
} from "../../components/cart/constants";
import {
	mergeAddonsCartLinesWithContentfulData,
	getToBeDisplayedMerchandiseData,
	mergeEventCartLinesWithContentfulData,
	mergeAndSortCartListByCreationDate,
} from "../../components/cart/utils";
import {
	Cart,
	NormalizedAddOns,
	NormalizedCartListItem,
} from "../../types/cart";
import {
	addCartLinesAction,
	CartActionTypes,
	createCartAction,
	fetchCartAction,
	fetchCartEventsAndMerchandisesContentfulDataAction,
	removeCartLineItemsAction,
	updateCartLinesAction,
	fetchCartByExternalIdAction,
} from "../actions/cartActions";
import { removeFromLocalStorage, setItemInLocalStorage } from "../localStorage";
import { EventItem } from "../queries/eventQueries";

export type CartStatus =
	| "FETCH_CART_PENDING"
	| "FETCH_CART_SUCCESS"
	| "FETCH_CART_FAILED"
	| "FETCH_CART_BY_EXTERNAL_ID_PENDING"
	| "FETCH_CART_BY_EXTERNAL_ID_SUCCESS"
	| "FETCH_CART_BY_EXTERNAL_ID_FAILED"
	| "CREATE_CART_PENDING"
	| "CREATE_CART_SUCCESS"
	| "CREATE_CART_FAILED"
	| "ADD_CART_LINES_PENDING"
	| "ADD_CART_LINES_SUCCESS"
	| "ADD_CART_LINES_FAILED"
	| "UPDATE_CART_LINES_PENDING"
	| "UPDATE_CART_LINES_SUCCESS"
	| "UPDATE_CART_LINES_FAILED"
	| "FETCH_CONTENFUL_DATA_OF_CART_ITEMS_PENDING"
	| "FETCH_CONTENFUL_DATA_OF_CART_ITEMS_SUCCESS"
	| "FETCH_CONTENFUL_DATA_OF_CART_ITEMS_FAILED"
	| "REMOVE_CART_LINES_PENDING"
	| "REMOVE_CART_LINES_SUCCESS"
	| "REMOVE_CART_LINES_FAILED";

export type CartState = {
	status?: CartStatus;
	cart?: Cart;
	numberOfItemsInCart?: number;
	normalizedCartList: Array<NormalizedCartListItem>;
	cartAddOnsList: NormalizedAddOns[];
	removingLineId?: string;
	addingAddonsId?: string;
	showNotification?: boolean;
};

export const cartSliceInitialState: CartState = {
	normalizedCartList: [],
	cartAddOnsList: [],
};

export const getNumberOfItemsInCart = (cart: Cart): number =>
	cart.lines?.edges.reduce((acc, { node }) => acc + node.quantity, 0);

export const saveDataInLocalStorageToFetchCartLater = (
	cart: Cart,
	countryCode: string
) => {
	setItemInLocalStorage("cart", {
		id: cart.id,
		updatedAt: cart.updatedAt,
		countryCode,
	});
};

type UpdatingCartActionsPayload = {
	cart: Cart;
	countryCode: string;
};

const actionTypesWhichUpdateTheCart = [
	`${CartActionTypes.fetchCart}/fulfilled`,
	`${CartActionTypes.addCartLines}/fulfilled`,
	`${CartActionTypes.updateCartLines}/fulfilled`,
	`${CartActionTypes.createCart}/fulfilled`,
	`${CartActionTypes.removeCartLine}/fulfilled`,
];

export const cartSlice = createSlice({
	name: "cart",
	initialState: cartSliceInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(CartActionTypes.emptyCart, (state) => {
				state.cart = undefined;
				state.numberOfItemsInCart = 0;
				state.normalizedCartList = [];
				state.cartAddOnsList = [];
				removeFromLocalStorage("cart");
			})
			.addCase(CartActionTypes.resetNotificationStatus, (state) => {
				state.showNotification = false;
			})
			.addCase(createCartAction.pending, (state) => {
				state.status = "CREATE_CART_PENDING";
			})
			.addCase(createCartAction.fulfilled, (state) => {
				state.status = "CREATE_CART_SUCCESS";
			})
			.addCase(createCartAction.rejected, (state) => {
				state.status = "CREATE_CART_FAILED";
			})
			.addCase(addCartLinesAction.pending, (state, { meta: { arg } }) => {
				state.addingAddonsId = arg.shopifyVariantId;
				state.status = "ADD_CART_LINES_PENDING";
			})
			.addCase(addCartLinesAction.fulfilled, (state) => {
				state.status = "ADD_CART_LINES_SUCCESS";
				state.addingAddonsId = undefined;
			})
			.addCase(addCartLinesAction.rejected, (state) => {
				state.status = "ADD_CART_LINES_FAILED";
			})
			.addCase(updateCartLinesAction.pending, (state, { meta: { arg } }) => {
				state.addingAddonsId = arg.shopifyVariantId; // when update action to increment addon item quantity is in pending state, we should set addingAddonsId to be able to put spinner on correct item in list
				state.status = "UPDATE_CART_LINES_PENDING";
			})
			.addCase(updateCartLinesAction.fulfilled, (state) => {
				state.status = "UPDATE_CART_LINES_SUCCESS";
				state.addingAddonsId = undefined;
			})
			.addCase(updateCartLinesAction.rejected, (state) => {
				state.status = "UPDATE_CART_LINES_FAILED";
			})
			.addCase(fetchCartAction.pending, (state) => {
				state.status = "FETCH_CART_PENDING";
			})
			.addCase(fetchCartAction.fulfilled, (state) => {
				state.status = "FETCH_CART_SUCCESS";
			})
			.addCase(fetchCartAction.rejected, (state) => {
				state.status = "FETCH_CART_FAILED";
			})
			.addCase(fetchCartByExternalIdAction.pending, (state) => {
				state.status = "FETCH_CART_BY_EXTERNAL_ID_PENDING";
			})
			.addCase(fetchCartByExternalIdAction.fulfilled, (state, { payload }) => {
				state.status = "FETCH_CART_BY_EXTERNAL_ID_SUCCESS";
				const { cart, countryCode } = payload as UpdatingCartActionsPayload;

				const cartCountryCodeIndex =
					CART_INDEX_OF_ATTRIBUTE[CART_ATTRIBUTE.CART_CREATED_COUNTRY];
				// check if users' current countryCode equals to countryCode set in to the attributes of cart in cart creation.
				if (countryCode === cart.attributes?.[cartCountryCodeIndex].value) {
					state.cart = cart;
					state.numberOfItemsInCart = getNumberOfItemsInCart(cart);
					saveDataInLocalStorageToFetchCartLater(cart, countryCode);
				} else {
					state.showNotification = true;
				}
			})
			.addCase(fetchCartByExternalIdAction.rejected, (state) => {
				state.status = "FETCH_CART_BY_EXTERNAL_ID_FAILED";
			})
			.addCase(
				removeCartLineItemsAction.pending,
				(state, { meta: { arg } }) => {
					const removingLineId = arg.queryVariables.lineIds[0];
					state.removingLineId = removingLineId; // when remove action in pending state, we should set removingLineId to be able to put spinner on correct item in list
					state.status = "REMOVE_CART_LINES_PENDING";
				}
			)
			.addCase(removeCartLineItemsAction.fulfilled, (state) => {
				state.status = "REMOVE_CART_LINES_SUCCESS";
				state.normalizedCartList = state.normalizedCartList.filter(
					(item) => item.lineId !== state.removingLineId
				);
				state.removingLineId = undefined;
			})
			.addCase(removeCartLineItemsAction.rejected, (state) => {
				state.removingLineId = undefined;
				state.status = "REMOVE_CART_LINES_FAILED";
			})
			.addCase(
				fetchCartEventsAndMerchandisesContentfulDataAction.pending,
				(state) => {
					state.status = "FETCH_CONTENFUL_DATA_OF_CART_ITEMS_PENDING";
				}
			)
			.addCase(
				fetchCartEventsAndMerchandisesContentfulDataAction.fulfilled,
				(state, action) => {
					state.status = "FETCH_CONTENFUL_DATA_OF_CART_ITEMS_SUCCESS";

					const {
						event,
						merchandise,
					}: { event: EventItem[]; merchandise: EventMerchandise[] } =
						normalizeContentfulDeliveryResponse(action.payload);
					// merge Event data added to cart with its corresponding Event equivalence in Contentful
					const cartProductsListEventUI = mergeEventCartLinesWithContentfulData(
						state.cart as Cart,
						event
					);

					// merge Addon data added to cart with its corresponding Addon equivalence in Contentful
					const cartProductListAddonsUI =
						mergeAddonsCartLinesWithContentfulData(
							state.cart as Cart,
							merchandise
						);

					// merge and sort all cart items (whether Event or Addon) based on their 'lineCreationData' as last added at the end
					const cartListDataUI = mergeAndSortCartListByCreationDate([
						...cartProductsListEventUI,
						...cartProductListAddonsUI,
					]);

					// get Addon data to display to users based on the events they have added to their cart
					const cartAddOnsList = getToBeDisplayedMerchandiseData(event);
					state.normalizedCartList = cartListDataUI;
					state.cartAddOnsList = cartAddOnsList;
				}
			)
			.addCase(
				fetchCartEventsAndMerchandisesContentfulDataAction.rejected,
				(state) => {
					state.status = "FETCH_CONTENFUL_DATA_OF_CART_ITEMS_FAILED";
				}
			)
			.addMatcher(
				(action) => actionTypesWhichUpdateTheCart.includes(action.type),
				(state, { payload }) => {
					const { cart, countryCode } = payload as UpdatingCartActionsPayload;
					state.cart = cart;
					state.numberOfItemsInCart = getNumberOfItemsInCart(cart);
					saveDataInLocalStorageToFetchCartLater(cart, countryCode);
				}
			);
	},
});

export const cartActions = cartSlice.actions;
