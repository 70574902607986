'use client';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    changeUserCountryCodeActions,
    fetchUserGeoLocationAction,
} from 'onair_frontend-lib';

const FetchGeoLocationOnAppLoad: FC<{
    children: React.JSX.Element;
}> = ({ children }) => {
    const dispatch = useDispatch();
    const isBrowser = typeof window !== 'undefined';

    if (isBrowser && process.env.GATSBY_ENABLE_SET_COUNTRY_CODE === 'true') {
        // set a global function to window to be able to change geoLocation manually for test purposes
        (window as any).setCountryCode = (param: string) => {
            dispatch(changeUserCountryCodeActions.changeUserCountryCode(param));
        };
    }

    useEffect(() => {
        // TO DO - remove as any
        dispatch(fetchUserGeoLocationAction() as any);
    }, [dispatch]);

    return <>{children}</>;
};

export default FetchGeoLocationOnAppLoad;
