'use client';
import React, { FC, useEffect } from 'react';
import { AuthToken } from 'onair_frontend-lib';
import { useAppSelector } from '../state/hooks';
import { getItemFromLocalStorage } from '../state/localStorage';
import {
    REMEMBER_USER_KEY,
    storeTokenLocalStorage,
} from '../state/tokenHelper';

type SyncTokenProviderProps = {
    children: React.ReactNode;
};

const SyncTokenProvider: FC<SyncTokenProviderProps> = ({ children }) => {
    const authToken: AuthToken | undefined = useAppSelector(
        (state) => state.auth.token,
    );

    useEffect(() => {
        const rememberUser = getItemFromLocalStorage(REMEMBER_USER_KEY);
        if (rememberUser && authToken) {
            // Update the stored token to keep in sync name, lastName, email, expiration date, etc.
            storeTokenLocalStorage(authToken);
        }
    }, [authToken]);

    return <>{children}</>;
};

export default SyncTokenProvider;
