// @ts-nocheck
'use client';
import FetchGeoLocationOnAppLoad from '@/providers/FetchGeoLocationOnAppLoad';
import FetchTicketsOnAppLoadProvider from '@/providers/FetchTicketsOnAppLoadProvider';
import { InitializeCartOnAppLoad } from '@/providers/InitializeCartOnAppLoad';
import SyncTokenProvider from '@/providers/SyncTokenProvider';
import { UserIdentify } from '@/providers/UserIdentify';
import { TrpcProvider } from '@/utils/trpc-provider';
import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import createStore from './createStore';
import { CookiesConsentManager } from '@/components/cookie-consent-manager';
import { useGetEnvironmentVariable } from '@/hooks/useGetEnvironmentVariable';

const store = createStore();

export { store };

export const Providers = ({
    children,
}: {
    children: JSX.Element | JSX.Element[] | Promise<JSX.Element> | ReactNode;
}) => {
    const { ENVIRONMENT, NEXT_PUBLIC_TEST_ONE_TRUST } =
        useGetEnvironmentVariable([
            'ENVIRONMENT',
            'NEXT_PUBLIC_TEST_ONE_TRUST',
        ]);
    return (
        <>
            {/* This can be fixed by adding an extra layer to the component that is server side so it can fetch the env from the server function */}
            {/* Disable it for OAR-4853 */}
            {/* {!NEXT_PUBLIC_TEST_ONE_TRUST === 'true' && ( */}
            <CookiesConsentManager />
            {/* // )} */}

            <Provider store={store}>
                <UserIdentify>
                    <TrpcProvider>
                        <SyncTokenProvider>
                            <FetchTicketsOnAppLoadProvider>
                                <FetchGeoLocationOnAppLoad>
                                    <InitializeCartOnAppLoad>
                                        {children}
                                    </InitializeCartOnAppLoad>
                                </FetchGeoLocationOnAppLoad>
                            </FetchTicketsOnAppLoadProvider>
                        </SyncTokenProvider>
                    </TrpcProvider>
                </UserIdentify>
            </Provider>
        </>
    );
};
