// import { useStaticQuery, graphql } from 'gatsby';
// import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Image from "next/image";

import React from "react";

export const CookiesBannerImage = () => {
	return (
		<div className="cookies-banner-container__image mt-2">
			<Image
				fill
				src={"/assets/images/cookies-image.png"}
				objectFit="contain"
				alt="Cookies in a bag"
			/>
		</div>
	);
};
