"use client";
import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AuthState, AuthToken, fetchShows } from "onair_frontend-lib";
import { useAppSelector } from "../state/hooks";

type FetchTicketsOnAppLoadProviderProps = {
	children: React.ReactNode;
};

const FetchTicketsOnAppLoadProvider: FC<FetchTicketsOnAppLoadProviderProps> = ({
	children,
}) => {
	const loginStatus: AuthState["loginStatus"] = useAppSelector(
		(state) => state.auth.loginStatus
	);
	const customerAccessToken: AuthToken["customerAccessToken"] | undefined =
		useAppSelector((state) => state.auth.token?.customerAccessToken);
	const jwt: AuthToken["jwt"] | undefined = useAppSelector(
		({ auth }) => auth?.token?.jwt
	);
	const expiresAt: AuthToken["expiresAt"] | undefined = useAppSelector(
		({ auth }) => auth?.token?.expiresAt
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (loginStatus === "LOGGED_IN" && customerAccessToken) {
			// TO DO - remove as any
			dispatch(
				fetchShows({
					auth: {
						jwt: jwt ?? "",
					},
				}) as any
			);
		}
	}, [loginStatus, dispatch, customerAccessToken, jwt, expiresAt]);

	return <>{children}</>;
};

export default FetchTicketsOnAppLoadProvider;
